import React from "react";
import '../css/main.css';
import { checkDevice, debounce, orientationChange, throttleResize } from "../utils/utils";
import { ActiveView, ChatMessage, Device, JensAIResponse, Language, Orientation } from "../utils/modelTypes";
import { NoisePng } from "./noisePng";
import { SiteSeperator } from "./siteSeperator";
import { HomeView } from "./homeView";
import { ProjectsView } from "./projectsView";
import { NavigationBar } from "./navigationBar";
import { ChatView } from "./chatView";
import { checkToken } from "../utils/apiRequests";
import { ContactView } from "./contactView";

export const Main: React.FC = () =>{
    const mainContainer = React.useRef<HTMLDivElement | null>(null);
    const contentWrapper = React.useRef<HTMLDivElement | null>(null);
    const [language, setLanguage] = React.useState<Language>({type: 'english'});
    const [orientation, setOrientation] = React.useState<Orientation>(orientationChange());
    const orientationRef = React.useRef<Orientation>(orientation);
    const [device, setDevice] = React.useState<Device>(checkDevice());
    const deviceRef = React.useRef<Device>(device);
    const [isStartMessage, setIsStartMessage] = React.useState<boolean>(true);
    const [messages, setMessages] = React.useState<ChatMessage[]>([]);
    const [activeView, setActiveView] = React.useState<ActiveView>({type: null});
    const [privacyPolicy, setPrivacyPolicy] = React.useState<boolean>(false);
    // const [virtuellKeyboard, setVirtuellKeyboard] = React.useState<boolean>(false);
    const [isNavigationBar, setIsNavigationBar] = React.useState<boolean>(true);

    React.useEffect(() => {
        deviceRef.current = device;
    }, [device]);

    React.useEffect(() => {
        orientationRef.current = orientation;
    }, [orientation]);

    React.useEffect(() =>{
        if(mainContainer.current !== null && contentWrapper.current !== null){
            if(activeView.type !== null){
                mainContainer.current.style.overflowY = `hidden`;
                contentWrapper.current.style.overflowY = `hidden`;
            }else{
                mainContainer.current.style.overflowY = `scroll`;
                contentWrapper.current.style.overflowY = `scroll`;
            }
        }
    },[activeView]);

    React.useEffect(() => {
        if(localStorage.getItem('language') !== null){
            const savedLanguage: Language = {type: localStorage.getItem('language') as 'german' | 'english'};
            setLanguage(savedLanguage);
        }

        const handleResize = () => {
            const newOrientation: Orientation = orientationChange();
            const newDevice: Device = checkDevice();
            // if(newOrientation.type === 'landscape' && newDevice.type === 'mobile' && window.innerHeight <= 200){
            //     setVirtuellKeyboard(true);
            // }else{
            //     setVirtuellKeyboard(false);
            // }
    
            if(newOrientation.type !== orientationRef.current.type) setOrientation(newOrientation);
            if(newDevice.type !== deviceRef.current.type) setDevice(newDevice);
        };
        const debounceHandleResize = debounce(handleResize, 250);

        window.addEventListener('resize', debounceHandleResize);

        return () => {
            window.removeEventListener('resize', debounceHandleResize);
        };
    }, []);

    const handleNavigation = React.useCallback((absElementName: ActiveView["type"]) =>{
        console.log(`clicked: ${absElementName}`)
        if(contentWrapper.current){
            const stateHeight: number = window.innerHeight;
            switch(absElementName){
                case 'projects':
                    contentWrapper.current.scrollTo({
                        top: stateHeight,
                        behavior: 'smooth'
                    });
                    return;
                case 'home':
                    contentWrapper.current.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    return;
                case 'contact':
                    contentWrapper.current.scrollTo({
                        top: stateHeight * 2,
                        behavior: 'smooth'
                    });
                    return;
                default:
                    if(activeView.type !== absElementName){
                        setActiveView({type: absElementName});
                    }
                    return null;
            }
        }
        
    },[activeView.type]);

    // React.useEffect(() =>{ 
    //     (async() =>{
    //         const token: string | null = localStorage.getItem("token") || '';
    //         const response: JensAIResponse | boolean = await checkToken(token);
    //         if(response && typeof response === 'object' && response.response === "Success") {
    //             setPrivacyPolicy(true);
    //         }else{
    //             setPrivacyPolicy(false);
    //         }
    //     })();
    // },[])
    
    console.log("main.tsx")
    
    return(
        <div className={'mainContainer'} ref={mainContainer}>
            <div className={`contentWrapper`} ref={contentWrapper}>
                {/* <div className={`backgroundBlur ${activeView.type !== null ? 'blur' : null}`}>
                    <HomeView device={device} orientation={orientation} language={language} setLanguage={setLanguage} handleNavigation={handleNavigation} />
                    <ProjectsView device={device} orientation={orientation}/>
                    <ContactView device={device} orientation={orientation} language={language} contentWrapper={contentWrapper} setIsNavigationBar={setIsNavigationBar}/>
                </div> */}

                
                {/* {activeView.type === 'projects' ? 
                <ProjectsView device={device} orientation={orientation} language={language} handleNavigation={handleNavigation}/>
                : null}
                {activeView.type === 'chat' ? 
                <ChatView device={device} orientation={orientation} language={language} handleNavigation={handleNavigation} isStartMessage={isStartMessage} setIsStartMessage={setIsStartMessage} messages={messages} setMessages={setMessages} privacyPolicy={privacyPolicy} setPrivacyPolicy={setPrivacyPolicy} />
                : null}
                {activeView.type === 'contact' ? 
                <ContactView device={device} orientation={orientation} language={language} handleNavigation={handleNavigation} />
                : null} */}
                {/* {activeView.type !== null?
                    null
                    :
                    <NavigationBar device={device} orientation={orientation} handleNavigation={handleNavigation} language={language} contentWrapper={contentWrapper} />
                } */}


                {/* {activeView.type === 'chat' ? 
                <ChatView device={device} orientation={orientation} language={language} handleNavigation={handleNavigation} isStartMessage={isStartMessage} setIsStartMessage={setIsStartMessage} messages={messages} setMessages={setMessages} privacyPolicy={privacyPolicy} setPrivacyPolicy={setPrivacyPolicy} />
                : null}
                {activeView.type !== 'chat' ? (
                    <NavigationBar device={device} orientation={orientation} handleNavigation={handleNavigation} language={language} contentWrapper={contentWrapper} />
                )
                : null} */}
                <div className={`underConstructionContainer`}>
                    <span className={`underConstructionText`}>Diese Seite befindet</span>
                    <span className={`underConstructionText`}>sich derzeit im</span>
                    <span className={`underConstructionText`}>Aufbau.</span>
                </div>
                
            </div>
            {/* {activeView.type !== null ? 
                null
                :
                <div className={`noiseWrapper ${activeView.type !== null ? 'blur' : null}`}>
                    <SiteSeperator/>
                    <NoisePng />
                </div>
            } */}
                
            
        </div>
    )
};