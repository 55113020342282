import { Device, Orientation } from "./modelTypes";

/**
 * Diese Konstantenfunktion überprüft, ob es sich bei dem Bildschirm um ein Touch-Display handelt oder nicht und gibt das Ergebnis als "true" oder "false" aus.
 * @returns true oder false ob Touch-Display
 */
export const isTouchDevice = () =>{
    return ((navigator.maxTouchPoints > 0));
}

/**
 * Diese Konstantenfunktion überprüft, ob die Bildschirmausrichtung Horizontal(Landscape) oder Vertikal(Portrait) ausgerichtet ist und schreibt das Ergebnis in die Hook-Konstante bzw. Setter Funktion
 * "setOrientation".
 * @param setOrientation Hook-Konstante bzw. Setter Funktion in welcher das Erfgebnis geschrieben werden soll.
 */
export const orientationChange = () =>{
    let orientation: Orientation = {type: 'landscape'};
    if(window.matchMedia("(orientation: portrait)").matches) {
        orientation = {type: 'portrait'};
    }else if(window.matchMedia("(orientation: landscape)").matches) {
        orientation = {type: 'landscape'};
    }
    return orientation;
}

// /**
//  * Diese Konstantenfunktion überprüft ob sich die Ausrichtung geändert hat.
//  * @param setOrientation Hook-Konstante bzw. Setter Funktion in welcher das Erfgebnis geschrieben werden soll.
//  */
// export const reportWindowSize = (setOrientation: React.Dispatch<React.SetStateAction<string>>) =>{
//     orientationChange(setOrientation);
// }

// /**
//  * Diese Konstantenfunktion dient zum Ausloggen des Users. Aktueller Name im LocalStorage wird gelöscht.
//  */
// export const handleLogout = (setLoginView: (arg0: boolean) => void) =>{
//     if(localStorage["skimsonUsername"]){
//         localStorage.removeItem("skimsonUsername");
//     }
//     setLoginView(true);
// }

/**
 * Überprüft die genauen FPS und gibt diese im return aus.
 * @returns Genaue Frames per Seconds
 */
const getFps = async() =>{
    return new Promise<number>((resolve) =>{
        requestAnimationFrame((t1: number) =>{
            requestAnimationFrame((t2: number) =>{
                return resolve(1000 / (t2-t1));
            })
        })
    })
}

/**
 * Überprüft die aktuellen FPS und gibt diese gerundet aus.
 * @returns Gerundete Frames per Seconds
 */
export const doCurrentFps = async() =>{
    let checkRounds: number = 0;
    let fps: number = 0;
    while(checkRounds < 3){
        checkRounds++;
        const tempFps: number = await getFps();
        if(tempFps >= 137 && tempFps <= 148){
            fps = 144;
            break;
        }else if(tempFps >= 117 && tempFps <= 125){
            fps = 120;
            break;
        }else if(tempFps >= 97 && tempFps <= 105){
            fps = 100;
            break;
        }
        else if(tempFps >= 87 && tempFps <= 95){
            fps = 90;
            break;
        }else if(tempFps >= 57 && tempFps <= 65){
            fps = 60;
            break;
        }else if(tempFps >= 47 && tempFps <= 55){
            fps = 50;
        }else if(tempFps >= 37 && tempFps <= 45){
            fps = 40;
        }else if(tempFps >= 27 && tempFps <= 35){
            fps = 30;
        }else{
            fps = tempFps;
        }
    }
    return fps;
}

export const openFullscreen = () => {
    const element: HTMLElement = document.documentElement;
    element.requestFullscreen();
}

export const closeFullscreen = () => {
    document.exitFullscreen();
}

// throttle für mouse
// throttle ist ein übergeordnete Hilfsfunktion, die dafür da ist, die übergebene Funktion zu überwachen, sodass die Funktion nur immer einmal zur gleichen Zeit ausgeführt werden kann.
export const throttleMouse = (func: (e: MouseEvent) => void, limit: number) =>{
    let inThrottle: boolean;
    return (...args: [MouseEvent]) =>{
        if(!inThrottle){
            func(...args);
            inThrottle = true;
            setTimeout(() => {
                inThrottle = false;

            },limit);
        }
    };
};

export const throttleTouch = (func: (e: PointerEvent) => void, limit: number) =>{
    let inThrottle: boolean;
    return (...args: [PointerEvent]) =>{
        if(!inThrottle){
            func(...args);
            inThrottle = true;
            setTimeout(() => {
                inThrottle = false;

            },limit);
        }
    };
};

// throttle für resize
export const throttleResize = (func: (...args: any[]) => void, limit: number): (() => void) => {
    let lastFunc: NodeJS.Timeout;
    let lastRan: number | null = null;
    return function(this: any, ...args: any[]) {
      if (!lastRan) {
        func.apply(this, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(() => {
          if ((Date.now() - (lastRan || 0)) >= limit) {
            func.apply(this, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - (lastRan || 0)));
      }
    };
};

// debounce wird verwendet um eine Verzögerung beim Resize herzustellen, damit es performanter ist 
export const debounce = (func: (...args: any[]) => void, delay: number): (() => void) => {
    let debounceTimer: NodeJS.Timeout;
    return function(this: any, ...args: any[]) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {console.log("JETZT");func.apply(this, args)}, delay);
    };
};

export const checkDevice = () =>{
    const isTouch: boolean = isTouchDevice();
	const isMobile: boolean = ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (window.innerWidth <= 768 && window.innerHeight <= 1024)) && isTouch) ? true : false;
	const device: Device = isMobile ? {type: 'mobile'} : {type: 'desktop'};
    return device;
}

export const doSVG = (element: HTMLDivElement, svg: string) =>{
    element.innerHTML = '';
    const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgElement.classList.add('newSVGElement');
    svgElement.innerHTML = svg;
    element.appendChild(svgElement);
}